.ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: 2px solid #A3BAFA;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 7000;
  pointer-events: none;
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #0A3DC7;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 7000;
  pointer-events: none;
}

.ring.hovered {
  width: 50px;
  height: 50px;
  border-width: 3px;
  border-color: lightgray;
}

.dot.hovered {
  display: none;
}